<template>
  <b-container class="h-100 mt-5">
    <b-row class="justify-content-center align-items-center">
      <b-col cols="10">
        <b-alert dismissible variant="danger" :show="hasErrors">
          <h4>We could not process your registration.</h4>
          <p v-for="errorMessage in errorMessages">
             {{ errorMessage }}
          </p>
        </b-alert>
        <b-card>
          <b-col cols="10" class="mx-auto">
            <b-form action="/registrations" :novalidate=true method="post" accept-charset="UTF-8">
              <input name="utf8" type="hidden" value="✓" />
              <input type="hidden" name="authenticity_token" :value="authenticity_token">
              <div class="card-title mt-2 mb-4">
                <h4>Account Registration</h4>
              </div>
              <div class="card-title border-bottom">
                <strong>Profile Details</strong>
                <div class="float-right">
                  <small>* Indicates Required Field</small>
                </div>
              </div>
              <div class="bg-light">
                <div class="pt-2 pb-2">
                  <small>
                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.email"
                          label="Email Address"
                          model-name="registration"
                          type="email"
                        >
                        </text-input>
                      </b-col>
                    </b-row>
                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.password"
                          model-name="registration"
                          type="password"
                          :autocomplete=false
                        >
                        </text-input>
                      </b-col>
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.password_confirmation"
                          model-name="registration"
                          type="password"
                          :autocomplete=false
                        >
                        </text-input>
                      </b-col>
                    </b-row>
                  </small>
                </div>
              </div>
              <div class="card-title border-bottom">
                <strong>Personal Information</strong>
              </div>
              <div class="bg-light">
                <div class="pt-2 pb-2">
                  <small>
                    <b-row class="ml-1 mr-1">
                      <b-col cols="4">
                        <text-input
                          v-bind="registration.first_name"
                          label="First Name"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                      <b-col cols="4">
                        <text-input
                          v-bind="registration.middle_name"
                          label="Middle Name"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                      <b-col cols="4">
                        <text-input
                          v-bind="registration.last_name"
                          label="Last Name"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                    </b-row>
                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <id-search-input
                          v-model="registration.dea_number.value"
                          qualifier="DEA"
                          @found="deaFound"
                          modelName="registration"
                          attrName="dea_number"
                          :required="registration.dea_number.required"
                          :errors="registration.dea_number.errors"
                          :withSearchAndAutoFill="false"
                        >
                        </id-search-input>
                      </b-col>
                      <b-col cols="6">
                        <id-search-input
                          v-model="registration.national_provider_id.value"
                          qualifier="NPI"
                          @found="npiFound"
                          modelName="registration"
                          attrName="national_provider_id"
                          :required="registration.national_provider_id.required"
                          :errors="registration.national_provider_id.errors"
                          :withSearchAndAutoFill="false"
                        >
                        </id-search-input>
                      </b-col>
                    </b-row>
                  </small>
                </div>
              </div>

              <div class="card-title border-bottom">
                <strong>Employer Information</strong>
              </div>
              <div class="bg-light">
                <div class="pt-2 pb-2">
                  <small>
                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.employer_name"
                          v-model="employerName"
                          label="Name"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                    </b-row>

                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.employer_address1"
                          label="Address"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.employer_address2"
                          label="Address (continued)"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                    </b-row>

                    <b-row class="ml-1 mr-1">
                      <b-col cols="4">
                        <text-input
                          v-bind="registration.employer_city"
                          label="City"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                      <b-col cols="4">
                        <select-input
                          :value="registration.employer_state.value"
                          label="State"
                          model-name="registration"
                          attr-name="employer_state"
                          :required="registration.employer_state.required"
                          :errors="registration.employer_state.errors"
                          :options="stateOptions"
                        >
                        </select-input>
                      </b-col>
                      <b-col cols="4">
                        <text-input
                          v-bind="registration.employer_zip_code"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                    </b-row>

                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.employer_phone"
                          v-model="employerPhone"
                          label="Phone"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                      <b-col cols="6">
                        <text-input
                          v-bind="registration.employer_fax"
                          label="Fax"
                          model-name="registration"
                        >
                        </text-input>
                      </b-col>
                    </b-row>

                    <b-row class="ml-1 mr-1">
                      <b-col cols="6">
                        <id-search-input
                          v-model="registration.employer_dea_number.value"
                          qualifier="DEA"
                          @found="employerDeaFound"
                          modelName="registration"
                          attrName="employer_dea_number"
                          :required="registration.employer_dea_number.required"
                          :errors="registration.employer_dea_number.errors"
                          :withSearchAndAutoFill="false"
                        >
                        </id-search-input>
                      </b-col>
                      <b-col cols="6">
                        <id-search-input
                          v-model="registration.ncpdp_nabp_number.value"
                          qualifier="NCPDP"
                          @found="ncpdpFound"
                          modelName="registration"
                          attrName="ncpdp_nabp_number"
                          :required="registration.ncpdp_nabp_number.required"
                          :errors="registration.ncpdp_nabp_number.errors"
                          :withSearchAndAutoFill="false"
                        >
                        </id-search-input>
                      </b-col>
                    </b-row>
                  </small>
                </div>
              </div>
              <div class="card-title border-bottom">
                <strong>Data Submission</strong>
              </div>
              <div class="bg-light">
                <div class="pt-2 pb-2">
                  <small>
                    <b-alert show variant="dark">
                      PMP Clearinghouse users are able to submit data through the web portal via manual entry or upload of ASAP files. Secure FTP (SFTP) access is available, and Real-Time submissions are also available in select states.
                    </b-alert>
                    <div class="ml-3">
                      <b-form-checkbox
                        v-model="registration.requires_sftp_access.value"
                        id="registration_requires_sftp_access"
                        name="registration[requires_sftp_access]"
                      >
                        Enable SFTP Access
                      </b-form-checkbox>
                    </div>
                    <b-col cols="6">
                      <b-collapse id="asap_infos" class='required' :visible="registration.requires_sftp_access.value">
                        <text-input
                          v-bind="sftpLogin.name"
                          label="SFTP Username"
                          :value="sftpUsername"
                          :disabled=true
                        >
                        </text-input>
                        <text-input
                          v-bind="registration.sftp_password"
                          label="SFTP Password"
                          model-name="registration"
                          type="password"
                          :autocomplete=false
                        >
                        </text-input>
                        <text-input
                          v-bind="registration.sftp_password_confirmation"
                          label="SFTP Password Confirmation"
                          model-name="registration"
                          type="password"
                          :autocomplete=false
                          :hint="passwordHint"
                        >
                        </text-input>
                      </b-collapse>
                    </b-col>
                    <div class="ml-3">
                      <b-form-checkbox
                        v-model="registration.requires_realtime_access.value"
                        id="registration_requires_realtime_access"

                        name="registration[requires_realtime_access]"
                      >
                        Enable Real-Time Access
                      </b-form-checkbox>
                    </div>
                    <b-col cols="6">
                      <b-collapse id="realtime_infos" class='required' :visible="registration.requires_realtime_access.value">
                        <text-input
                          v-bind="registration.realtime_username"
                          label="Real-Time Username"
                          model-name="registration"
                        >
                        </text-input>
                        <text-input
                          v-bind="registration.realtime_password"
                          label="Real-Time Password"
                          model-name="registration"
                          type="password"
                          :autocomplete=false
                        >
                        </text-input>
                        <text-input
                          v-bind="registration.realtime_password_confirmation"
                          label="Real-Time Password Confirmation"
                          model-name="registration"
                          type="password"
                          :autocomplete=false
                          :hint="passwordHint"
                        >
                        </text-input>
                      </b-collapse>
                    </b-col>
                  </small>
                </div>
              </div>
              <div class="card-title border-bottom">
                <strong>Submission Destinations</strong>
              </div>
              <div class="bg-light">
                <div class="pt-2 pb-2">
                  <small>
                    <b-alert show variant="dark">
                      Only select states for which you are required to submit data.  In most cases, a state requires submissions when there is a physical location dispensing controlled substances within the given state and/or when a dispenser is shipping (i.e. mail orders) controlled substances into the state. Please consult the state’s governing PDMP body for clarification on if you are required to submit data to the respective state.
                      <br><br><strong>Please Note:</strong> Selecting additional states <strong>DOES NOT</strong> enable interstate data sharing.
                    </b-alert>
                    <div class="ml-3">
                      <checkbox-group
                        v-bind="registration.pmps"
                        :options="pmpOptions"
                        label="States"
                        model-name="registration"
                        name="pmp_ids"
                      >
                      </checkbox-group>
                    </div>
                  </small>
                </div>
              </div>
              <div class="mt-4 mb-4">
                <b-button type="submit" variant="primary">Submit</b-button>
              </div>
            </b-form>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import IdentifierInput from './IdentifierInput.vue'
  import TextInput from './TextInput.vue'
  import SelectInput from './SelectInput.vue'
  import CheckboxGroup from './CheckboxGroup.vue'

  export default {
    components: {
      'text-input': TextInput,
      'id-search-input': IdentifierInput,
      'select-input': SelectInput,
      'checkbox-group': CheckboxGroup
    },
    data() {
      return {
        registration: this.fields,
        employerName: this.fields.employer_name.value || '',
        employerPhone: this.fields.employer_phone.value || '',
        passwordHint: "Password must include at least 8 characters, including 1 capital letter, 1 lowercase letter, and 1 special character (such as !,@,#,$)"
      }
    },
    props: {
      authenticity_token: {
        type: String,
        required: true
      },
      fields: {
        type:  Object,
        required: true
      },
      stateOptions: {
        type: Array,
        required: true
      },
      pmpOptions: {
        type: Array,
        required: true
      },
      sftpLogin: {
        type: Object
      },
      sftpSlice: {
        type: String,
        required: true
      }
    },
    computed: {
      errorMessages() {
        return this.flat(Object.keys(this.fields).map(key => this.fields[key].errors));
      },
      hasErrors() { 
        return !!this.errorMessages.length;
      },
      phoneSlice() {
        return this.employerPhone.replace(/\D/g, '');
      },
      nameSlice() {
        return this.employerName.replace(/\W/g, '').substr(0, 5).toLowerCase();
      },
      sftpUsername() {
        if (this.phoneSlice && this.nameSlice) {
          return `${this.nameSlice}${this.phoneSlice}${this.sftpSlice && '@' + this.sftpSlice}`
        } else {
          return '';
        }
      }
    },
    methods: {
      flat(arrays) {
        return [].concat.apply([], arrays);
      },
      deaFound(idType, idData) {
        this.registration.first_name.value = idData.first_name,
        this.registration.middle_name.value = idData.middle_name;
        this.registration.last_name.value = idData.last_name;
      },
      npiFound(idType, idData) {
        this.registration.first_name.value = idData.first_name || idData.authorized_official_first_name;
        this.registration.middle_name.value = idData.middle_name || idData.authorized_official_middle_name;
        this.registration.last_name.value = idData.last_name || idData.authorized_official_last_name;
      },
      employerDeaFound(idType, idData) {
        this.employerName = idData.name;
        this.registration.employer_address1.value = idData.address_line1;
        this.registration.employer_address2.value = idData.address_line2;
        this.registration.employer_city.value = idData.city;
        this.registration.employer_state.value = idData.state;
        this.registration.employer_zip_code.value = idData.postal_code;
      },
      ncpdpFound(idType, idData) {
        this.employerName = idData.name;
        this.employerPhone = idData.location_phone;
        this.registration.employer_address1.value = idData.mailing_address1;
        this.registration.employer_address2.value = idData.mailing_address2;
        this.registration.employer_city.value = idData.mailing_city;
        this.registration.employer_state.value = idData.mailing_state_code;
        this.registration.employer_zip_code.value = idData.mailing_zip_code;
        this.registration.employer_fax.value = idData.location_fax;
      }
    }
  }
</script>
