var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "small",
    [
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  value: _vm.npiValue,
                  label: "NPI Number - DSP14",
                  "model-name": "universal_claim_form",
                  name: "pharmacist_npi",
                  required: _vm.npiRequired,
                  errors: _vm.npiErrors
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  value: _vm.stateLicenseValue,
                  label: "State License Number - DSP15",
                  "model-name": "universal_claim_form",
                  name: "pharmacist_state_license",
                  required: _vm.stateLicenseRequired,
                  errors: _vm.stateLicenseErrors
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }