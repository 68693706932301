<template>
  <label :for="forId">
    <slot></slot>
    <abbr v-if="required" title="required">*</abbr>
  </label>
</template>

<script>
  export default {
    props: {
      forId: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        required: false
      }
    }
  }
</script>
