<template>
  <div>
    <b-container fluid>
      <b-col cols="12" class="my-1" align="right">
        <b-input
            v-model="searchTerm"
            @input="getDispenserGuidesBySearchTerm(searchTerm)"
            placeholder="Search by state name or file name"
            class="col-sm-3"
            align="right"
        >
        </b-input>
      </b-col>
      <br>
      <b-table
          striped
          hover
          :fields="fields"
          :per-page="perPage"
          :items="dispenserGuides"
      >
        <template v-slot:cell(dispenser_guide_file_url)="row">
          <b-button
            size="sm"
            variant="primary"
            v-on:click="previewDispenserGuide(row.item.dispenser_guide_file_url)"
          >
            {{ row.item.dispenser_guide_file_file_name }}
          </b-button>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
              size="sm"
              variant="primary"
              v-on:click="deleteDispenserGuidePath(row.item.id)"
          >
            Delete
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col cols="12" class="my-1">
          <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :totalRows="totalEntries"
              align="right"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios"

export default {
  data() {
    return {
      dispenserGuides: [],
      searchTerm: '',
      totalEntries: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'pmp_name',
          label: 'State'
        },
        {
          key: 'dispenser_guide_file_url',
          label: 'Dispenser Guide'
        },
        {
          key: 'updated_at',
          label: 'Updated at',
          formatter: (value, _key, _item) => {
            return new Date(value).toLocaleDateString("en-US");
          }
        },
        {
          key: 'actions',
          label: 'Actions'
        }
      ],
    }
  },
  computed: {
    lookupPath() {
      return `/dispenser_guides`
    }
  },
  methods: {
    getDispenserGuidesBySearchTerm(searchTerm) {
      return (searchTerm.length >= 3 || searchTerm.length == 0) && this.getDispenserGuides()
    },
    getDispenserGuides() {
      let vm = this;

      axios.get(
          vm.lookupPath, {
            params: {
              page: this.currentPage,
              searchTerm: this.searchTerm
            }
          }
      ).then(function (response) {
        vm.dispenserGuides = response.data.dispenser_guides;
        vm.totalEntries = response.data.total_entries;
        vm.errorMessages = [];
      })
          .catch(function (error) {
            vm.errorMessages = [vm.notFoundError];
          });
    },
    previewDispenserGuide(url) {
      window.open(url, "_blank");
    },
    deleteDispenserGuidePath(dispenserGuideId) {
      let vm = this;

      this.$bvModal.msgBoxConfirm('You are about to delete this dispenser guide. Is this correct?', {
        title: 'Delete dispenser guide',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Back',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          axios.delete(
              'dispenser_guides/'.concat(dispenserGuideId)
          ).then(function (response) {
            window.location.href = '/dispenser_guides';
            vm.errorMessages = [];
          })
          .catch(function (error) {
            vm.errorMessages = [vm.notFoundError];
          });
        }
      })
    }
  },
  mounted() {
    this.getDispenserGuides()
  },
  watch: {
    currentPage() {
      this.getDispenserGuides()
    }
  }
}
</script>