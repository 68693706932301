var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { attrs: { for: _vm.forId } },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.required
        ? _c("abbr", { attrs: { title: "required" } }, [_vm._v("*")])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }