var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "small",
    [
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Name - PHA04",
                  "model-name": "universal_claim_form",
                  name: "pharmacy_name",
                  required: _vm.nameRequired,
                  errors: _vm.nameErrors
                },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Phone Number - PHA10",
                  "model-name": "universal_claim_form",
                  name: "pharmacy_phone_number",
                  required: _vm.phoneNumberRequired,
                  errors: _vm.phoneNumberErrors
                },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("pharmacy-identifiers", {
        attrs: { identifiers: _vm.identifiers },
        on: {
          "dea-found": _vm.deaFound,
          "ncpdp-found": _vm.ncpdpFound,
          "npi-found": _vm.npiFound,
          "sl-found": _vm.slFound
        }
      }),
      _vm._v(" "),
      _vm.showSlSeparately
        ? _c(
            "b-row",
            { staticClass: "ml-1 mr-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("text-input", {
                    attrs: {
                      label: "Permit Number/License Number - PHA13",
                      "model-name": "universal_claim_form",
                      name: "pharmacy_permit_license_number",
                      required: _vm.permitLicenseNumberRequired,
                      errors: _vm.permitLicenseNumberErrors
                    },
                    model: {
                      value: _vm.permitLicenseNumber,
                      callback: function($$v) {
                        _vm.permitLicenseNumber = $$v
                      },
                      expression: "permitLicenseNumber"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Contact Name - PHA11",
                  "model-name": "universal_claim_form",
                  name: "pharmacy_contact_name",
                  required: _vm.contactNameRequired,
                  errors: _vm.contactNameErrors
                },
                model: {
                  value: _vm.contactName,
                  callback: function($$v) {
                    _vm.contactName = $$v
                  },
                  expression: "contactName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Address - PHA05",
                  "model-name": "universal_claim_form",
                  name: "pharmacy_address",
                  required: _vm.addressRequired,
                  errors: _vm.addressErrors
                },
                model: {
                  value: _vm.address,
                  callback: function($$v) {
                    _vm.address = $$v
                  },
                  expression: "address"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("text-input", {
                attrs: {
                  label: "City - PHA07",
                  "model-name": "universal_claim_form",
                  name: "pharmacy_city",
                  required: _vm.cityRequired,
                  errors: _vm.cityErrors
                },
                model: {
                  value: _vm.city,
                  callback: function($$v) {
                    _vm.city = $$v
                  },
                  expression: "city"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("select-input", {
                attrs: {
                  label: "State - PHA08",
                  "model-name": "universal_claim_form",
                  "attr-name": "pharmacy_state",
                  required: _vm.stateRequired,
                  errors: _vm.stateErrors,
                  "option-groups": _vm.stateOptions
                },
                model: {
                  value: _vm.state,
                  callback: function($$v) {
                    _vm.state = $$v
                  },
                  expression: "state"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "4" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Postal Code - PHA09",
                  "model-name": "universal_claim_form",
                  name: "pharmacy_zip_code",
                  required: _vm.zipCodeRequired,
                  errors: _vm.zipCodeErrors
                },
                model: {
                  value: _vm.zipCode,
                  callback: function($$v) {
                    _vm.zipCode = $$v
                  },
                  expression: "zipCode"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }