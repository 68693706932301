<template>
  <b-card bg-variant="light" class="ml-1 mr-1">
    <b-form-group
      id="prescriber-identifiers-group"
      :description="getDescription">
      <b-row v-for="(identifier, index) in list" v-bind:key="identifier.input_type">
        <b-col cols="12">
          <div v-if="allOptional && index != 0">
            <label>Or</label>
          </div>
        </b-col>
        <b-col cols="6">
          <identifier-search-input
            :value="identifier.value"
            :qualifier="identifier.input_type"
            :state_cd="identifier.state_cd"
            @found="identifierFound"
            :label="identifier.input_label"
            :model-name="identifier.model_name"
            :attr-name="identifier.attribute_name"
            :required="identifier.is_required"
            :errors="identifier.errors">
          </identifier-search-input>
        </b-col>
        <b-col cols="6" v-if="identifier.input_type == 'dea'">
          <text-input
              :value="deaSuffix"
              label="DEA Suffix - PRE03"
              model-name="universal_claim_form"
              name="prescriber_institutional_number"
              :required="deaSuffixRequired"
              :errors="deaSuffixErrors">
          </text-input>
        </b-col>
      </b-row>
    </b-form-group>
  </b-card>
</template>

<script>
  import IdentifierInput from '../IdentifierInput.vue'
  import TextInput from '../TextInput.vue'

  export default {
    data() {
      return {
        list: this.identifiers,
        deaSuffix: this.deaSuffixValue
      }
    },
    computed: {
      allOptional() {
        return !this.identifiers.some((i) => i.is_required === true);
      },
      getDescription(){
        if (this.identifiers.length > 1) {
          return 'All identifiers marked with an asterisk (if any) must be provided. ' +
                 'At least one of the identifiers must be valid.';
        } else{
          return '';
        }
      }
    },
    props: {
      identifiers: {
        type: Array,
        required: true
      },
      deaSuffixValue: {
        type: String,
        required: false
      },
      deaSuffixRequired: {
        type: Boolean,
        default: false
      },
      deaSuffixErrors: {
        type: Array,
        default: () => []
      },
    },
    components: {
      'identifier-search-input': IdentifierInput,
      'text-input': TextInput
    },
    methods: {
      identifierFound(idType, idData) {
        this.$emit(idType + "-found", idData);
      }
    }
  }
</script>
