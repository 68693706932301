import { render, staticRenderFns } from "./PrescriberIdentifiers.vue?vue&type=template&id=36af9369&"
import script from "./PrescriberIdentifiers.vue?vue&type=script&lang=js&"
export * from "./PrescriberIdentifiers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/app/rails/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36af9369')) {
      api.createRecord('36af9369', component.options)
    } else {
      api.reload('36af9369', component.options)
    }
    module.hot.accept("./PrescriberIdentifiers.vue?vue&type=template&id=36af9369&", function () {
      api.rerender('36af9369', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/UniversalClaimForm/PrescriberIdentifiers.vue"
export default component.exports