<template>
  <small>
    <prescriber-identifiers
      :identifiers=identifiers
      @dea-found="deaFound"
      @npi-found="npiFound"
      @sl-found="slFound"
      :dea-suffix-value=deaSuffix
      :dea-suffix-required=deaSuffixRequired
      :dea-suffix-errors=deaSuffixErrors>
    </prescriber-identifiers>

    <b-row class="ml-1 mr-1">
      <b-col cols="6">
        <br>
        <text-input
            v-model="xdea"
            label="XDEA Number - PRE09"
            model-name="universal_claim_form"
            name="prescriber_xdea_number"
            :required="xdeaRequired"
            :errors="xdeaErrors">
        </text-input>
      </b-col>
      <b-col cols="6">
        <br>
        <select-input
          v-model="jurisdiction"
          label="State License Jurisdiction - PRE10"
          model-name="universal_claim_form"
          attr-name="prescriber_state_license_jurisdiction"
          :required="jurisdictionRequired"
          :errors="jurisdictionErrors"
          :option-groups="jurisdictionOptions"
        >
        </select-input>
      </b-col>
    </b-row>

    <b-row class="ml-1 mr-1">
      <b-col cols="6">
        <text-input
            v-model="firstName"
            label="First Name - PRE06"
            model-name="universal_claim_form"
            name="prescriber_first_name"
            :required="firstNameRequired"
            :errors="firstNameErrors">
        </text-input>
      </b-col>
      <b-col cols="6">
        <text-input
            v-model="lastName"
            label="Last Name - PRE05"
            model-name="universal_claim_form"
            name="prescriber_last_name"
            :required="lastNameRequired"
            :errors="lastNameErrors">
        </text-input>
      </b-col>
    </b-row>

    <b-row class="ml-1 mr-1">
      <b-col cols="6">
        <text-input
            v-model="phoneNumber"
            label="Phone Number - PRE08"
            model-name="universal_claim_form"
            name="prescriber_phone_number"
            :required="phoneNumberRequired"
            :errors="phoneNumberErrors">
        </text-input>
      </b-col>
    </b-row>
  </small>
</template>

<script>
  import PrescriberIdentifiers from './PrescriberIdentifiers.vue'
  import TextInput from '../TextInput.vue'
  import SelectInput from '../SelectInput.vue'

  export default {
    data() {
      return {
        identifiers: this.prescriberIdentifiers,
        deaSuffix: this.deaSuffixValue,
        xdea: this.xdeaValue,
        firstName: this.firstNameValue,
        lastName: this.lastNameValue,
        phoneNumber: this.phoneNumberValue,
        jurisdiction: this.jurisdictionValue
      }
    },
    props: {
      prescriberIdentifiers: {
        type: Array,
        default: () => []
      },
      deaSuffixValue: {
        type: String,
        required: false
      },
      deaSuffixRequired: {
        type: Boolean,
        default: false
      },
      deaSuffixErrors: {
        type: Array,
        default: () => []
      },
      xdeaValue: {
        type: String,
        required: false
      },
      xdeaRequired: {
        type: Boolean,
        default: false
      },
      xdeaErrors: {
        type: Array,
        default: () => []
      },
      firstNameValue: {
        type: String,
        required: false
      },
      firstNameRequired: {
        type: Boolean,
        default: false
      },
      firstNameErrors: {
        type: Array,
        default: () => []
      },
      lastNameValue: {
        type: String,
        required: false
      },
      lastNameRequired: {
        type: Boolean,
        default: false
      },
      lastNameErrors: {
        type: Array,
        default: () => []
      },
      phoneNumberValue: {
        type: String,
        required: false
      },
      phoneNumberRequired: {
        type: Boolean,
        default: false
      },
      phoneNumberErrors: {
        type: Array,
        default: () => []
      },
      jurisdictionValue: {
        type: String,
        required: false
      },
      jurisdictionRequired: {
        type: Boolean,
        default: false
      },
      jurisdictionErrors: {
        type: Array,
        default: () => []
      },
      jurisdictionOptions: {
        type: Object,
        required: true
      }
    },
    components: {
      'prescriber-identifiers': PrescriberIdentifiers,
      'text-input': TextInput,
      'select-input': SelectInput
    },
    methods: {
      deaFound(idData) {
        this.firstName = idData['first_name'];
        this.lastName = idData['last_name'];
      },
      npiFound(idData) {
        this.firstName = idData['first_name'] || idData['authorized_official_first_name'];
        this.lastName = idData['last_name'] || idData['authorized_official_last_name'];
        this.phoneNumber = idData['business_phone'] || idData['authorized_official_phone'];
      },
      slFound(idData) {
        this.firstName = idData['first_name'];
        this.lastName = idData['last_name'];
        this.phoneNumber = idData['phone']
      }
    }
  }
</script>
