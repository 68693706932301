<template>
  <small>
    <b-row class="ml-1 mr-1">
      <b-col cols="12">
        <text-input
          v-model="name"
          label="Name - PHA04"
          model-name="universal_claim_form"
          name="pharmacy_name"
          :required="nameRequired"
          :errors="nameErrors"
        >
        </text-input>
      </b-col>
    </b-row>
    <b-row class="ml-1 mr-1">
      <b-col cols="6">
        <text-input
          v-model="phoneNumber"
          label="Phone Number - PHA10"
          model-name="universal_claim_form"
          name="pharmacy_phone_number"
          :required="phoneNumberRequired"
          :errors="phoneNumberErrors"
        >
        </text-input>
      </b-col>
    </b-row>

    <pharmacy-identifiers
      :identifiers=identifiers
      @dea-found="deaFound"
      @ncpdp-found="ncpdpFound"
      @npi-found="npiFound"
      @sl-found="slFound">
    </pharmacy-identifiers>

    <b-row class="ml-1 mr-1" v-if="showSlSeparately">
      <b-col cols="6">
        <text-input
          v-model="permitLicenseNumber"
          label="Permit Number/License Number - PHA13"
          model-name="universal_claim_form"
          name="pharmacy_permit_license_number"
          :required="permitLicenseNumberRequired"
          :errors="permitLicenseNumberErrors"
        >
        </text-input>
      </b-col>
    </b-row>

    <b-row class="ml-1 mr-1">
      <b-col cols="6">
        <text-input
          v-model="contactName"
          label="Contact Name - PHA11"
          model-name="universal_claim_form"
          name="pharmacy_contact_name"
          :required="contactNameRequired"
          :errors="contactNameErrors"
        >
        </text-input>
      </b-col>
    </b-row>

    <b-row class="ml-1 mr-1">
      <b-col cols="6">
        <text-input
          v-model="address"
          label="Address - PHA05"
          model-name="universal_claim_form"
          name="pharmacy_address"
          :required="addressRequired"
          :errors="addressErrors"
        >
        </text-input>
      </b-col>
    </b-row>

    <b-row class="ml-1 mr-1">
      <b-col cols="4">
        <text-input
          v-model="city"
          label="City - PHA07"
          model-name="universal_claim_form"
          name="pharmacy_city"
          :required="cityRequired"
          :errors="cityErrors"
        >
        </text-input>
      </b-col>
      <b-col cols="4">
        <select-input
          v-model="state"
          label="State - PHA08"
          model-name="universal_claim_form"
          attr-name="pharmacy_state"
          :required="stateRequired"
          :errors="stateErrors"
          :option-groups="stateOptions"
        >
        </select-input>
      </b-col>
      <b-col cols="4">
        <text-input
          v-model="zipCode"
          label="Postal Code - PHA09"
          model-name="universal_claim_form"
          name="pharmacy_zip_code"
          :required="zipCodeRequired"
          :errors="zipCodeErrors"
        >
        </text-input>
      </b-col>
    </b-row>
  </small>
</template>

<script>
  import PharmacyIdentifiers from './PharmacyIdentifiers.vue'
  import TextInput from '../TextInput.vue'
  import SelectInput from '../SelectInput.vue'

  export default {
    data() {
      return {
        identifiers: this.pharmacyIdentifiers,
        permitLicenseNumber: this.permitLicenseNumberValue,
        name: this.nameValue,
        phoneNumber: this.phoneNumberValue,
        contactName: this.contactNameValue,
        address: this.addressValue,
        city: this.cityValue,
        state: this.stateValue,
        zipCode: this.zipCodeValue
      }
    },
    components: {
      'text-input': TextInput,
      'select-input': SelectInput,
      'pharmacy-identifiers': PharmacyIdentifiers
    },
    computed: {
      showSlSeparately() {
        return !this.identifiers.some((i) => i.input_type === 'sl');
      }
    },
    props: {
      pharmacyIdentifiers: {
        type: Array,
        default: () => []
      },
      nameValue: {
        type: String,
        required: false
      },
      nameRequired: {
        type: Boolean,
        default: false
      },
      nameErrors: {
        type: Array,
        default: () => []
      },
      phoneNumberValue: {
        type: String,
        required: false
      },
      phoneNumberRequired: {
        type: Boolean,
        default: false
      },
      phoneNumberErrors: {
        type: Array,
        default: () => []
      },
      permitLicenseNumberValue: {
        type: String,
        required: false
      },
      permitLicenseNumberRequired: {
        type: Boolean,
        default: false
      },
      permitLicenseNumberErrors: {
        type: Array,
        default: () => []
      },
      contactNameValue: {
        type: String,
        required: false
      },
      contactNameRequired: {
        type: Boolean,
        default: false
      },
      contactNameErrors: {
        type: Array,
        default: () => []
      },
      addressValue: {
        type: String,
        required: false
      },
      addressRequired: {
        type: Boolean,
        default: false
      },
      addressErrors: {
        type: Array,
        default: () => []
      },
      cityValue: {
        type: String,
        required: false
      },
      cityRequired: {
        type: Boolean,
        default: false
      },
      cityErrors: {
        type: Array,
        default: () => []
      },
      stateValue: {
        type: String,
        required: false
      },
      stateRequired: {
        type: Boolean,
        default: false
      },
      stateErrors: {
        type: Array,
        default: () => []
      },
      stateOptions: {
        type: Object,
        required: true
      },
      zipCodeValue: {
        type: String,
        required: false
      },
      zipCodeRequired: {
        type: Boolean,
        default: false
      },
      zipCodeErrors: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      deaFound(idData) {
        this.name = idData['name']
        this.phoneNumber = idData['contact_phone']
        this.address = idData['address_line1']
        this.city = idData['city']
        this.state = idData['state']
        this.zipCode = idData['postal_code']
      },
      ncpdpFound(idData) {
        this.name = idData['name']
        this.phoneNumber = idData['contact_phone']
        this.address = idData['location_address1']
        this.city = idData['location_city']
        this.state = idData['location_state_code']
        this.zipCode = idData['location_zip_code']
      },
      npiFound(idData) {
        this.state = idData['business_state']
        this.city = idData['business_city']
        this.zipCode = idData['business_postal_code']
        this.name = idData['organization_name'] || `${idData['first_name']} ${idData['last_name']}`
        this.address = idData['business_address_line1']
        this.phoneNumber = idData['business_phone']
      },
      slFound(idData) {
        this.name = idData['name']
        this.phoneNumber = idData['phone']
        this.address = idData['address']
        this.city = idData['city']
        this.state = idData['state']
        this.zipCode = idData['postal_code']
      }
    }
  }
</script>
