var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { state: _vm.state, "invalid-feedback": _vm.errorMessage } },
    [
      _c(
        "b-form-label",
        {
          class: { "font-weight-bold": _vm.required },
          attrs: { required: _vm.required, forId: _vm.inputId }
        },
        [_vm._v("\n    " + _vm._s(_vm.inputLabel) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            class: { "font-weight-bold": _vm.required },
            attrs: {
              state: _vm.state,
              id: _vm.inputId,
              name: _vm.inputName,
              maxlength: "255",
              size: "255"
            },
            model: {
              value: _vm.identifierValue,
              callback: function($$v) {
                _vm.identifierValue = $$v
              },
              expression: "identifierValue"
            }
          }),
          _vm._v(" "),
          _vm.withSearchAndAutoFill
            ? _c("b-input-group-append", [
                _c(
                  "button",
                  {
                    staticClass: "btn tipsy",
                    attrs: {
                      id: _vm.buttonId,
                      type: "button",
                      "data-toggle": "tipsy",
                      title: "Search and Autofill"
                    },
                    on: { click: _vm.search }
                  },
                  [_c("i", { staticClass: "fas fa-search" })]
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }