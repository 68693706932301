<template>
  <b-form-group
    :state="state"
    :invalid-feedback="errorMessage"
  >
    <b-form-label :required="required" :forId="inputId" :class="{'font-weight-bold': required}"">
      {{ label }}
    </b-form-label>
    <b-form-select
      v-model="selectValue"
      :value="value"
      :state="state"
      :id="inputId"
      :name="inputName"
      :class="{'font-weight-bold': required}"
    >
      <option value=""></option>
      <option v-for="option in options" :value="option[1]">
          {{ option[0] }}
      </option>
      <optgroup v-for="(group, name) in optionGroups" :label="name">
        <option v-for="option in group" :value="option[1]">
          {{ option[0] }}
        </option>
      </optgroup>
    </b-form-select>
  </b-form-group>
</template>

<script>
  import BFormLabel from './BFormLabel.vue'

  export default {
    components: {
      "b-form-label": BFormLabel
    },
    data() {
      return {
        errorMessages: this.errors
      }
    },
    computed: {
        state() {
            return this.invalid ? false : null;
        },
        invalid() {
            return !!this.errorMessages.length;
        },
        selectValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        inputName() {
            return `${this.modelName}[${this.attrName}]`;
        },
        inputId() {
            return `${this.modelName}_${this.attrName}`;
        },
        errorMessage() {
            return this.errorMessages.join(' and ');
        },
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        default: false
      },
      modelName: {
        type: String,
        required: true
      },
      attrName: {
        type: String,
        required: true
      },
      errors: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array,
        default: () => []
      },
      optionGroups: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>
