<template>
  <span v-if="success" class='notice success'>
    <i class='fa fa-check'></i>
    Submitted
  </span>
  <span v-else-if="error" class='notice error'>
    <i class='fa fa-times'></i>
    Error
  </span>
  <b-button v-else @click="submit" variant="primary" class="submit" :class="buttonClass" :disabled="submitting">
    <span v-if="submitting">
      <b-spinner small></b-spinner>
      Submitting
    </span>
    <span v-else>
      {{ label }}
      </br>
      {{ date }}
    </span>
  </b-button>
</template>

<script>
  import axios from "axios"

  export default {
    data() {
      return {
        error: null,
        success: null,
        submitting: false,
        submitPath: `/zero_report_profiles/${this.profileId}/submissions`
      }
    },
    computed: {
      label() {
        return this.day.charAt(0).toUpperCase() + this.day.slice(1);
      },
      buttonClass() {
        if (this.submitting) {
          return 'submitting';
        } else if (this.submitted) {
          return 'submitted';
        } else {
          return '';
        }
      }
    },
    props: {
      day: {
        type: String,
        required: true
      },
      date: {
        type: String,
        required: true
      },
      profileId: {
        type: Number,
        required: true
      },
      submitted: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      submit() {
        let vm = this
        vm.submitting = true;

        axios.post(this.submitPath, {
          day: vm.day,
        })
        .then(function(response) {
          vm.submitting = false;
          vm.success = true;
        })
        .catch(function(error) {
          vm.submitting = false;
          vm.error = true;
        })
      }
    }
  }
</script>

<style scoped>
  span.notice {
    display: inline-block;
  }

  span.success {
    color: green;
    margin: 2px 28px 2px 0px;
    padding: 6px 0px 6px 16px;
  }

  span.error {
    color: red;
    margin: 2px 28px 2px 20px;
    padding: 6px 12px 6px 28px;
  }

  button.submit {
    font-size: 0.8em;
    padding: 2px 30px;
    margin-left: 0px;
    margin-right: 10px;
  }

  button.submitting {
    padding: 11px 21px 12px;
  }

  button.submitted {
    visibility: hidden;
  }
</style>
