<template>
  <div>
    <br>
    <b-container fluid>
      <b-col cols="12" class="my-1" align="right">
        <b-input
          v-model="searchTerm"
          @input="getUsersBySearchTerm(searchTerm)"
          placeholder="Search by email or account name"
          class="col-sm-3"
          align="right"
        >
        </b-input>
      </b-col>
      <br>

      <b-table
        striped
        hover
        :fields="fields"
        :per-page="perPage"
        :items="users"
      >
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            variant="primary"
            v-on:click="impersonatePath(row.item.id)"
          >
            Impersonate
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col cols="12" class="my-1">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :totalRows="totalEntries"
            align="right"
          >
          </b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import axios from "axios"

  export default {
    data() {
      return {
        users: [],
        searchTerm: '',
        totalEntries: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15],
        filter: null,
        filterOn: [],
        fields: [
            'email',
            'first_name',
            'last_name',
            'account_name',
            'actions'
        ],
      }
    },
    computed: {
      lookupPath() {
        return `/impersonates`
      }
    },
    methods: {
      getUsersBySearchTerm(searchTerm) {
          return (searchTerm.length >= 3 || searchTerm.length == 0) && this.getUsers()
      },
      getUsers() {
        let vm = this;

        axios.get(
          vm.lookupPath, {
            params: {
                page: this.currentPage,
                searchTerm: this.searchTerm
            }
          }
        ).then(function (response) {
          vm.users = response.data.users;
          vm.totalEntries = response.data.total_entries;
          vm.errorMessages = [];
        })
        .catch(function (error) {
          vm.errorMessages = [vm.notFoundError];
        });
      },
      impersonatePath(userId) {
        let vm = this;

        axios.post(
            'impersonates/'.concat(userId)
        ).then(function (response) {
          window.location.href = '/';
          vm.errorMessages = [];
        })
        .catch(function (error) {
          vm.errorMessages = [vm.notFoundError];
        });;
      }
    },
    mounted() {
      this.getUsers()
    },
    watch: {
      currentPage() {
        this.getUsers()
      }
    }
  }
</script>
