<template>
  <div>
    <b-modal
      hide-footer
      id="sftp-conversion-modal"
      ref="sftp-conversion-modal"
      :no-close-on-backdrop=true
      :hide-header-close=true
      :no-close-on-esc=true
    >
      <template #modal-title>
        <h3>
          <b-icon icon="exclamation-triangle-fill" scale="1" variant="danger"></b-icon>
          <span>SFTP Transition</span>
        </h3>
      </template>

      <div class="step-1" v-if="step == 1">
        The PMP Clearinghouse will be transitioning to a new SFTP server.
        This transition will require you to update your current SFTP account credentials and hostname for your substance reports to be submitted.
        <br />
        <b-button class="mt-3 float-right" variant="primary" @click="showForm">Continue</b-button>
      </div>
      <div class="step-2" v-if="step == 2">
        <h5>Create new SFTP account</h5>
        <p>
          Creating a new account will not affect your current SFTP submissions. The account details below will be used once you transition to the new SFTP server.
        </p>
        <strong>Account details</strong>
        <hr class="mt-0" />
        <b-form action="/sftp_logins/transition" :novalidate=true method="post" accept-charset="UTF-8">
        <input name="utf8" type="hidden" value="✓" />
        <div class="bg-light">
          <div class="pt-2 pb-2">
            <small>
              <b-row class="ml-1 mr-1">
                <b-col cols="12">
                  <text-input
                    label="Hostname"
                    :value="hostname"
                    :disabled=true
                    :errors="sftp_login.hostname.errors"
                  ></text-input>
                </b-col>
              </b-row>
              <b-row class="ml-1 mr-1">
                <b-col cols="6">
                    <text-input
                      label="SFTP Username"
                      v-model="sftp_login.name.value"
                      :errors="sftp_login.name.errors"
                      name="name"
                      model-name="sftp_login"
                    >
                    </text-input>
                </b-col>
              </b-row>
              <b-row class="ml-1 mr-1">
                <b-col cols="6">
                  <text-input
                    label="SFTP Password"
                    type="password"
                    :autocomplete=false
                    :hint="passwordHint"
                    v-model="sftp_login.password.value"
                    :errors="sftp_login.password.errors"
                    name="password"
                    model-name="sftp_login"
                  >
                  </text-input>
                </b-col>
                <b-col cols="6">
                  <text-input
                    label="SFTP Password Confirmation"
                    type="password"
                    :autocomplete=false
                    v-model="sftp_login.password_confirmation.value"
                    :errors="sftp_login.password_confirmation.errors"
                    name="password_confirmation"
                    model-name="sftp_login"
                  >
                  </text-input>
                </b-col>
              </b-row>
            </small>
          </div>
        </div>
        <hr class="mt-0" />
        <small><span style="color: red;">*</span>Write down the hostname, username and password for later use.</small>
        <br />
        <br />
        <b-button variant="primary" @click="submit" class="submit float-right" :class="buttonClass" :disabled="submitting">Continue</b-button>
        </b-form>
      </div>
      <div class="step-3" v-if="step == 3">
        <h4 class="text-center">
          Next steps
        </h4>
        <p>
          You have successfully created credentials for the new SFTP server. To complete the SFTP transition process and begin using the server now, click <b-link :href="sftp_login.success_path">here</b-link>.
          <br />
          <br />
          To complete the SFTP transition process later, click <b-link href="#" @click="closeModal">here</b-link>.
          <br />
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import TextInput from './TextInput.vue'
  import axios from "axios"

  export default {
    components: {
      'text-input': TextInput,
    },
    mounted() {
      this.$refs['sftp-conversion-modal'].show();
    },
    data() {
      return {
        step: 1,
        hostname: 'submissions.healthcarecoordination.net',
        passwordHint: "Password must include at least 8 characters, including 1 capital letter, 1 lowercase letter, and 1 special character (such as !,@,#,$)",
        submitPath: '/sftp_logins/transition',
        submitting: false,
        sftp_login: {
          hostname: { errors: [] },
          name: { value: this.initialName, errors: [] },
          success_path: this.successPath,
          password: { value: '', errors: [] },
          password_confirmation: { value: '', errors: [] },
        }
      }
    },
    computed: {
      buttonClass() {
        if (this.submitting) return 'submitting';
        if (this.submitted) return 'submitted';
        return '';
      }
    },
    props: {
      initialName: {
        type: String,
        required: true
      },
      successPath: {
        type: String,
        required: true
      },
    },
    methods: {
      showForm() {
        this.step = 2;
      },
      submit() {
        let vm = this;
        vm.submitting = true;

        axios.post(this.submitPath, {
          sftp_login: Object.fromEntries(
            vm.sftp_login_attrs().map(key => ([key, vm.sftp_login[key].value]))
          )
        })
        .then(function(response) {
          vm.submitting = false;
          vm.clearErrors();
          vm.step = 3;
        })
        .catch(function(error) {
          vm.submitting = false;
          vm.clearErrors();
          let data = error.response.data;
          Object.keys(data).map(key => vm.sftp_login[key].errors = data[key]);
        })
      },
      clearErrors(){
        let vm = this;
        vm.sftp_login_attrs(true).map(key => vm.sftp_login[key].errors = []);
      },
      sftp_login_attrs(withHostname = false){
        let attrs = ['name', 'password', 'password_confirmation'];
        if (withHostname == true) { attrs.push('hostname'); }
        return attrs;
      },
      closeModal(){
        this.$refs['sftp-conversion-modal'].hide();
      }
    },
  }
</script>
