<template>
  <b-card
      bg-variant="light"
      title="Dispenser Guide Upload"
      sub-title="Submit a new Dispenser Guide"
  >
    <b-card-text>
      <b-row>
        <b-col cols='3'>
          <b-form-group>
            <b-form-select
                class="mt-3"
                v-model="selectedState"
                :options="stateOptions">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Select PMP</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols='3'>
          <b-form-group
            :invalid-feedback="invalidFeedback"
            :valid-feedback="validFeedback"
            :state="success"
          >
            <b-form-file
                class="mt-3"
                :file-name-formatter="formatNames"
                v-model="file"
                placeholder="Choose a file or drop it here..."
                @change='clear()'
                :state="success"
            ></b-form-file>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>
    <template v-slot:footer>
      <b-button variant='primary' :disabled='submitDisabled' @click="uploadClicked()">
          <span v-if="submitting">
            <b-spinner small></b-spinner>
            Uploading
          </span>
        <span v-else>
            Upload
          </span>
      </b-button>
    </template>
  </b-card>
</template>

<script>
import axios from "axios"

export default {
  data(){
    return {
      file: null,
      invalidFeedback: '',
      validFeedback: '',
      success: null,
      submitting: false,
      selectedState: null,
      stateOptions: []
    }
  },
  computed: {
    submitDisabled() {
      return !this.file || !this.selectedState || this.submitting;
    },
    lookupStatesPath() {
      return `/pmps/admin`
    }
  },
  methods: {
    clear() {
      this.invalidFeedback = '';
      this.validFeedback = '';
      this.success = null;
    },
    formatNames(files) {
      return files[0].name.slice(0, 30);
    },
    uploadClicked() {
      this.clear();

      let vm = this;

      this.$bvModal.msgBoxConfirm('You are about to upload this Dispenser Guide. Is this correct?', {
        title: 'Upload Dispenser Guide',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Upload',
        cancelTitle: 'Change',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          vm.uploadFile();
        }
      })
    },
    uploadFile(){
      this.submitting = true;

      let vm = this;

      let formData = new FormData();
      formData.append('pmp_id', this.selectedState);
      formData.append('dispenser_guide_file', this.file);

      axios.post( '/dispenser_guides',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then(function(response){
        vm.success = true;
        vm.validFeedback = 'Dispenser Guide successfully uploaded.'
      })
      .then(function (response) {
        window.location.href = '/dispenser_guides';
      })
      .catch(function (error) {
        vm.invalidFeedback = error.response.data['error'];
        vm.success = false;
      })
      .then(function(){
        vm.submitting = false;
      });
    },
    getStateOptions() {
      let vm = this;

      axios.get(
        vm.lookupStatesPath
      ).then(function (response) {
        response.data.forEach(
          state => vm.stateOptions.push(
            { value: state['id'], text: state['name'] }
          )
        );

        vm.errorMessages = [];
      })
      .catch(function (error) {
        vm.errorMessages = [vm.notFoundError];
      });
    }
  },
  mounted() {
    this.getStateOptions()
  }
}
</script>
