var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { state: _vm.state, "invalid-feedback": _vm.errorMessage } },
    [
      _c(
        "b-form-label",
        {
          class: { "font-weight-bold": _vm.required },
          attrs: { required: _vm.required, forId: _vm.inputId }
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-form-select",
        {
          class: { "font-weight-bold": _vm.required },
          attrs: {
            value: _vm.value,
            state: _vm.state,
            id: _vm.inputId,
            name: _vm.inputName
          },
          model: {
            value: _vm.selectValue,
            callback: function($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue"
          }
        },
        [
          _c("option", { attrs: { value: "" } }),
          _vm._v(" "),
          _vm._l(_vm.options, function(option) {
            return _c("option", { domProps: { value: option[1] } }, [
              _vm._v("\n        " + _vm._s(option[0]) + "\n    ")
            ])
          }),
          _vm._v(" "),
          _vm._l(_vm.optionGroups, function(group, name) {
            return _c(
              "optgroup",
              { attrs: { label: name } },
              _vm._l(group, function(option) {
                return _c("option", { domProps: { value: option[1] } }, [
                  _vm._v("\n        " + _vm._s(option[0]) + "\n      ")
                ])
              }),
              0
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }