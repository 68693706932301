var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "h-100 mt-5" },
    [
      _c(
        "b-row",
        { staticClass: "justify-content-center align-items-center" },
        [
          _c(
            "b-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "b-alert",
                {
                  attrs: {
                    dismissible: "",
                    variant: "danger",
                    show: _vm.hasErrors
                  }
                },
                [
                  _c("h4", [_vm._v("We could not process your registration.")]),
                  _vm._v(" "),
                  _vm._l(_vm.errorMessages, function(errorMessage) {
                    return _c("p", [
                      _vm._v(
                        "\n           " + _vm._s(errorMessage) + "\n        "
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "b-card",
                [
                  _c(
                    "b-col",
                    { staticClass: "mx-auto", attrs: { cols: "10" } },
                    [
                      _c(
                        "b-form",
                        {
                          attrs: {
                            action: "/registrations",
                            novalidate: true,
                            method: "post",
                            "accept-charset": "UTF-8"
                          }
                        },
                        [
                          _c("input", {
                            attrs: { name: "utf8", type: "hidden", value: "✓" }
                          }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              name: "authenticity_token"
                            },
                            domProps: { value: _vm.authenticity_token }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-title mt-2 mb-4" }, [
                            _c("h4", [_vm._v("Account Registration")])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-title border-bottom" },
                            [
                              _c("strong", [_vm._v("Profile Details")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "float-right" }, [
                                _c("small", [
                                  _vm._v("* Indicates Required Field")
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bg-light" }, [
                            _c("div", { staticClass: "pt-2 pb-2" }, [
                              _c(
                                "small",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Email Address",
                                                  "model-name": "registration",
                                                  type: "email"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.email,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "model-name": "registration",
                                                  type: "password",
                                                  autocomplete: false
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.password,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "model-name": "registration",
                                                  type: "password",
                                                  autocomplete: false
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .password_confirmation,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-title border-bottom" },
                            [_c("strong", [_vm._v("Personal Information")])]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bg-light" }, [
                            _c("div", { staticClass: "pt-2 pb-2" }, [
                              _c(
                                "small",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "First Name",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.first_name,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Middle Name",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.middle_name,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Last Name",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.last_name,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("id-search-input", {
                                            attrs: {
                                              qualifier: "DEA",
                                              modelName: "registration",
                                              attrName: "dea_number",
                                              required:
                                                _vm.registration.dea_number
                                                  .required,
                                              errors:
                                                _vm.registration.dea_number
                                                  .errors,
                                              withSearchAndAutoFill: false
                                            },
                                            on: { found: _vm.deaFound },
                                            model: {
                                              value:
                                                _vm.registration.dea_number
                                                  .value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.registration.dea_number,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "registration.dea_number.value"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("id-search-input", {
                                            attrs: {
                                              qualifier: "NPI",
                                              modelName: "registration",
                                              attrName: "national_provider_id",
                                              required:
                                                _vm.registration
                                                  .national_provider_id
                                                  .required,
                                              errors:
                                                _vm.registration
                                                  .national_provider_id.errors,
                                              withSearchAndAutoFill: false
                                            },
                                            on: { found: _vm.npiFound },
                                            model: {
                                              value:
                                                _vm.registration
                                                  .national_provider_id.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.registration
                                                    .national_provider_id,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "registration.national_provider_id.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-title border-bottom" },
                            [_c("strong", [_vm._v("Employer Information")])]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bg-light" }, [
                            _c("div", { staticClass: "pt-2 pb-2" }, [
                              _c(
                                "small",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Name",
                                                  "model-name": "registration"
                                                },
                                                model: {
                                                  value: _vm.employerName,
                                                  callback: function($$v) {
                                                    _vm.employerName = $$v
                                                  },
                                                  expression: "employerName"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.employer_name,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Address",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .employer_address1,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Address (continued)",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .employer_address2,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "City",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.employer_city,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c("select-input", {
                                            attrs: {
                                              value:
                                                _vm.registration.employer_state
                                                  .value,
                                              label: "State",
                                              "model-name": "registration",
                                              "attr-name": "employer_state",
                                              required:
                                                _vm.registration.employer_state
                                                  .required,
                                              errors:
                                                _vm.registration.employer_state
                                                  .errors,
                                              options: _vm.stateOptions
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "4" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .employer_zip_code,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Phone",
                                                  "model-name": "registration"
                                                },
                                                model: {
                                                  value: _vm.employerPhone,
                                                  callback: function($$v) {
                                                    _vm.employerPhone = $$v
                                                  },
                                                  expression: "employerPhone"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.employer_phone,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Fax",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.employer_fax,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    { staticClass: "ml-1 mr-1" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("id-search-input", {
                                            attrs: {
                                              qualifier: "DEA",
                                              modelName: "registration",
                                              attrName: "employer_dea_number",
                                              required:
                                                _vm.registration
                                                  .employer_dea_number.required,
                                              errors:
                                                _vm.registration
                                                  .employer_dea_number.errors,
                                              withSearchAndAutoFill: false
                                            },
                                            on: { found: _vm.employerDeaFound },
                                            model: {
                                              value:
                                                _vm.registration
                                                  .employer_dea_number.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.registration
                                                    .employer_dea_number,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "registration.employer_dea_number.value"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c("id-search-input", {
                                            attrs: {
                                              qualifier: "NCPDP",
                                              modelName: "registration",
                                              attrName: "ncpdp_nabp_number",
                                              required:
                                                _vm.registration
                                                  .ncpdp_nabp_number.required,
                                              errors:
                                                _vm.registration
                                                  .ncpdp_nabp_number.errors,
                                              withSearchAndAutoFill: false
                                            },
                                            on: { found: _vm.ncpdpFound },
                                            model: {
                                              value:
                                                _vm.registration
                                                  .ncpdp_nabp_number.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.registration
                                                    .ncpdp_nabp_number,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "registration.ncpdp_nabp_number.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-title border-bottom" },
                            [_c("strong", [_vm._v("Data Submission")])]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bg-light" }, [
                            _c("div", { staticClass: "pt-2 pb-2" }, [
                              _c(
                                "small",
                                [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "dark" } },
                                    [
                                      _vm._v(
                                        "\n                    PMP Clearinghouse users are able to submit data through the web portal via manual entry or upload of ASAP files. Secure FTP (SFTP) access is available, and Real-Time submissions are also available in select states.\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml-3" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            id:
                                              "registration_requires_sftp_access",
                                            name:
                                              "registration[requires_sftp_access]"
                                          },
                                          model: {
                                            value:
                                              _vm.registration
                                                .requires_sftp_access.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.registration
                                                  .requires_sftp_access,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "registration.requires_sftp_access.value"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Enable SFTP Access\n                    "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-collapse",
                                        {
                                          staticClass: "required",
                                          attrs: {
                                            id: "asap_infos",
                                            visible:
                                              _vm.registration
                                                .requires_sftp_access.value
                                          }
                                        },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "SFTP Username",
                                                  value: _vm.sftpUsername,
                                                  disabled: true
                                                }
                                              },
                                              "text-input",
                                              _vm.sftpLogin.name,
                                              false
                                            )
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "SFTP Password",
                                                  "model-name": "registration",
                                                  type: "password",
                                                  autocomplete: false
                                                }
                                              },
                                              "text-input",
                                              _vm.registration.sftp_password,
                                              false
                                            )
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label:
                                                    "SFTP Password Confirmation",
                                                  "model-name": "registration",
                                                  type: "password",
                                                  autocomplete: false,
                                                  hint: _vm.passwordHint
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .sftp_password_confirmation,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml-3" },
                                    [
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            id:
                                              "registration_requires_realtime_access",
                                            name:
                                              "registration[requires_realtime_access]"
                                          },
                                          model: {
                                            value:
                                              _vm.registration
                                                .requires_realtime_access.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.registration
                                                  .requires_realtime_access,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "registration.requires_realtime_access.value"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Enable Real-Time Access\n                    "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-collapse",
                                        {
                                          staticClass: "required",
                                          attrs: {
                                            id: "realtime_infos",
                                            visible:
                                              _vm.registration
                                                .requires_realtime_access.value
                                          }
                                        },
                                        [
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Real-Time Username",
                                                  "model-name": "registration"
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .realtime_username,
                                              false
                                            )
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Real-Time Password",
                                                  "model-name": "registration",
                                                  type: "password",
                                                  autocomplete: false
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .realtime_password,
                                              false
                                            )
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "text-input",
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label:
                                                    "Real-Time Password Confirmation",
                                                  "model-name": "registration",
                                                  type: "password",
                                                  autocomplete: false,
                                                  hint: _vm.passwordHint
                                                }
                                              },
                                              "text-input",
                                              _vm.registration
                                                .realtime_password_confirmation,
                                              false
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "card-title border-bottom" },
                            [_c("strong", [_vm._v("Submission Destinations")])]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "bg-light" }, [
                            _c("div", { staticClass: "pt-2 pb-2" }, [
                              _c(
                                "small",
                                [
                                  _c(
                                    "b-alert",
                                    { attrs: { show: "", variant: "dark" } },
                                    [
                                      _vm._v(
                                        "\n                    Only select states for which you are required to submit data.  In most cases, a state requires submissions when there is a physical location dispensing controlled substances within the given state and/or when a dispenser is shipping (i.e. mail orders) controlled substances into the state. Please consult the state’s governing PDMP body for clarification on if you are required to submit data to the respective state.\n                    "
                                      ),
                                      _c("br"),
                                      _c("br"),
                                      _c("strong", [_vm._v("Please Note:")]),
                                      _vm._v(" Selecting additional states "),
                                      _c("strong", [_vm._v("DOES NOT")]),
                                      _vm._v(
                                        " enable interstate data sharing.\n                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "ml-3" },
                                    [
                                      _c(
                                        "checkbox-group",
                                        _vm._b(
                                          {
                                            attrs: {
                                              options: _vm.pmpOptions,
                                              label: "States",
                                              "model-name": "registration",
                                              name: "pmp_ids"
                                            }
                                          },
                                          "checkbox-group",
                                          _vm.registration.pmps,
                                          false
                                        )
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt-4 mb-4" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "primary" }
                                },
                                [_vm._v("Submit")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }