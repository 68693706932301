var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { staticClass: "ml-1 mr-1", attrs: { "bg-variant": "light" } },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            id: "prescriber-identifiers-group",
            description: _vm.getDescription
          }
        },
        _vm._l(_vm.list, function(identifier, index) {
          return _c(
            "b-row",
            { key: identifier.input_type },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm.allOptional && index != 0
                  ? _c("div", [_c("label", [_vm._v("Or")])])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("identifier-search-input", {
                    attrs: {
                      value: identifier.value,
                      qualifier: identifier.input_type,
                      state_cd: identifier.state_cd,
                      label: identifier.input_label,
                      "model-name": identifier.model_name,
                      "attr-name": identifier.attribute_name,
                      required: identifier.is_required,
                      errors: identifier.errors
                    },
                    on: { found: _vm.identifierFound }
                  })
                ],
                1
              ),
              _vm._v(" "),
              identifier.input_type == "dea"
                ? _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("text-input", {
                        attrs: {
                          value: _vm.deaSuffix,
                          label: "DEA Suffix - PRE03",
                          "model-name": "universal_claim_form",
                          name: "prescriber_institutional_number",
                          required: _vm.deaSuffixRequired,
                          errors: _vm.deaSuffixErrors
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }