var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid" },
    [
      _c("br"),
      _vm._v(" "),
      _c(
        "b-tabs",
        { attrs: { "content-class": "mt-4" } },
        [
          _c(
            "b-tab",
            { attrs: { title: "Dispenser Guide Listings", active: "" } },
            [_c("dispenser-guides")],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Dispenser Guide Upload" } },
            [_c("dispenser-guide-form")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }