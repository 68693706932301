<template>
    <small>
        <b-row class="ml-1 mr-1">
            <b-col cols="6">
                <text-input
                    :value="npiValue"
                    label="NPI Number - DSP14"
                    model-name="universal_claim_form"
                    name="pharmacist_npi"
                    :required="npiRequired"
                    :errors="npiErrors"
                >
                </text-input>
            </b-col>
            <b-col cols="6">
                <text-input
                    :value="stateLicenseValue"
                    label="State License Number - DSP15"
                    model-name="universal_claim_form"
                    name="pharmacist_state_license"
                    :required="stateLicenseRequired"
                    :errors="stateLicenseErrors"
                >
                </text-input>
            </b-col>
        </b-row>
    </small>
</template>

<script>
    import TextInput from '../TextInput.vue'

    export default {
        data() {
            return {
                npi: this.npiValue,
                stateLicense: this.stateLicenseValue
            }
        },
        components: {
            'text-input': TextInput
        },
        props: {
            npiValue: {
                type: String,
                required: false
            },
            npiRequired: {
                type: Boolean,
                default: false
            },
            npiErrors: {
                type: Array,
                default: () => []
            },
            stateLicenseValue: {
                type: String,
                required: false
            },
            stateLicenseRequired: {
                type: Boolean,
                default: false
            },
            stateLicenseErrors: {
                type: Array,
                default: () => []
            }
        }
    }
</script>
