/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue';
import VueResource from 'vue-resource'
import axios from 'axios'
import App from './components/App.vue';
import Notifications from 'vue-notification';
import PrescriberFields from './components/UniversalClaimForm/PrescriberFields.vue';
import PharmacyFields from './components/UniversalClaimForm/PharmacyFields.vue';
import PharmacistFields from './components/UniversalClaimForm/PharmacistFields.vue';
import RegistrationForm from './components/RegistrationForm.vue';
import SftpConversionModal from './components/SftpConversionModal.vue';
import ZeroReportProfiles from './components/ZeroReportProfiles.vue';
import Impersonates from "./components/Impersonates";
import DispenserGuidesTabs from "./components/DispenserGuidesTabs";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(Notifications)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueResource)

document.addEventListener('DOMContentLoaded', () => {
  let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common['Accept'] = 'application/json'

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status == 401) {
      window.location.replace('/users/sign_in');
    } else {
      return Promise.reject(error);
    }
  });

  new Vue(App).$mount('#app')

  var zeroReportProfiles = document.getElementById("zero-report-profiles")
  if (zeroReportProfiles != null) {
    var props = JSON.parse(zeroReportProfiles.dataset.props);

    new Vue({
      el: zeroReportProfiles,
      render: h => h(ZeroReportProfiles, { props: props })
    })
  }

  new Vue({
    el: "#prescriber-section",
    components: {
      'prescriber-fields': PrescriberFields
    }
  })

  new Vue({
    el: "#pharmacy-section",
    components: {
      'pharmacy-fields': PharmacyFields
    }
  })

  new Vue({
    el: "#pharmacist-section",
    components: {
      'pharmacist-fields': PharmacistFields
    }
  })

  new Vue({
    el: "#registration-form",
    components: {
      'registration-form': RegistrationForm
    }
  })

  new Vue({
    el: "#impersonates",
    components: {
      'impersonates-fields': Impersonates
    }
  })

  new Vue({
    el: "#dispenser-guides",
    components: {
      'dispenser-guides-tabs': DispenserGuidesTabs
    }
  })

  new Vue({
    el: "#sftp-conversion",
    components: {
      'sftp-conversion-modal': SftpConversionModal
    }
  })

  $.get("/alerts", null, null, 'json')
    .done(function (alerts) {
      $.each(alerts, function( index, alert ) {
        Vue.notify({
          group: 'app',
          title: alert.subject,
          text: alert.description,
          type: alert.status_type
        });
      });
    });
})
