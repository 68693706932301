var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { cols: "12", align: "right" } },
            [
              _c("b-input", {
                staticClass: "col-sm-3",
                attrs: {
                  placeholder: "Search by state name or file name",
                  align: "right"
                },
                on: {
                  input: function($event) {
                    return _vm.getDispenserGuidesBySearchTerm(_vm.searchTerm)
                  }
                },
                model: {
                  value: _vm.searchTerm,
                  callback: function($$v) {
                    _vm.searchTerm = $$v
                  },
                  expression: "searchTerm"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("b-table", {
            attrs: {
              striped: "",
              hover: "",
              fields: _vm.fields,
              "per-page": _vm.perPage,
              items: _vm.dispenserGuides
            },
            scopedSlots: _vm._u([
              {
                key: "cell(dispenser_guide_file_url)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.previewDispenserGuide(
                              row.item.dispenser_guide_file_url
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(row.item.dispenser_guide_file_file_name) +
                            "\n        "
                        )
                      ]
                    )
                  ]
                }
              },
              {
                key: "cell(actions)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.deleteDispenserGuidePath(row.item.id)
                          }
                        }
                      },
                      [_vm._v("\n          Delete\n        ")]
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "my-1", attrs: { cols: "12" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      "per-page": _vm.perPage,
                      totalRows: _vm.totalEntries,
                      align: "right"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }