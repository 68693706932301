<template>
  <div class="container-fluid">
    <br>
    <b-tabs content-class="mt-4">
      <b-tab title="Dispenser Guide Listings" active>
        <dispenser-guides></dispenser-guides>
      </b-tab>
      <b-tab title="Dispenser Guide Upload">
        <dispenser-guide-form></dispenser-guide-form>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import DispenserGuides from "./DispenserGuides";
import DispenserGuideForm from "./DispenserGuideForm";

export default {
  components: {
    'dispenser-guides': DispenserGuides,
    'dispenser-guide-form': DispenserGuideForm
  }
}
</script>