var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { state: _vm.state, "invalid-feedback": _vm.errorMessage } },
    [
      _c(
        "b-form-label",
        {
          class: { "font-weight-bold": _vm.required },
          attrs: { required: _vm.required, forId: _vm.inputId }
        },
        [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
      ),
      _vm._v(" "),
      _c("b-form-input", {
        class: { "font-weight-bold": _vm.required },
        attrs: {
          state: _vm.state,
          id: _vm.inputId,
          name: _vm.inputName,
          maxlength: "255",
          size: "255",
          type: _vm.type,
          autocomplete: _vm.autocompleteAttr,
          disabled: _vm.disabled
        },
        model: {
          value: _vm.inputValue,
          callback: function($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue"
        }
      }),
      _vm._v(" "),
      _vm.hint
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v("\n    " + _vm._s(_vm.hint) + "\n  ")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }