var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    { attrs: { state: _vm.state, "invalid-feedback": _vm.errorMessage } },
    [
      _c("b-form-label", { attrs: { required: _vm.required } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + "\n  ")
      ]),
      _vm._v(" "),
      _c("b-form-checkbox-group", {
        attrs: {
          checked: _vm.value,
          required: _vm.required,
          options: _vm.options,
          "text-field": "name",
          "value-field": "id",
          name: _vm.inputName,
          id: _vm.inputId,
          stacked: "",
          state: _vm.state
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }