var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "small",
    [
      _c("prescriber-identifiers", {
        attrs: {
          identifiers: _vm.identifiers,
          "dea-suffix-value": _vm.deaSuffix,
          "dea-suffix-required": _vm.deaSuffixRequired,
          "dea-suffix-errors": _vm.deaSuffixErrors
        },
        on: {
          "dea-found": _vm.deaFound,
          "npi-found": _vm.npiFound,
          "sl-found": _vm.slFound
        }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("br"),
              _vm._v(" "),
              _c("text-input", {
                attrs: {
                  label: "XDEA Number - PRE09",
                  "model-name": "universal_claim_form",
                  name: "prescriber_xdea_number",
                  required: _vm.xdeaRequired,
                  errors: _vm.xdeaErrors
                },
                model: {
                  value: _vm.xdea,
                  callback: function($$v) {
                    _vm.xdea = $$v
                  },
                  expression: "xdea"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("br"),
              _vm._v(" "),
              _c("select-input", {
                attrs: {
                  label: "State License Jurisdiction - PRE10",
                  "model-name": "universal_claim_form",
                  "attr-name": "prescriber_state_license_jurisdiction",
                  required: _vm.jurisdictionRequired,
                  errors: _vm.jurisdictionErrors,
                  "option-groups": _vm.jurisdictionOptions
                },
                model: {
                  value: _vm.jurisdiction,
                  callback: function($$v) {
                    _vm.jurisdiction = $$v
                  },
                  expression: "jurisdiction"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  label: "First Name - PRE06",
                  "model-name": "universal_claim_form",
                  name: "prescriber_first_name",
                  required: _vm.firstNameRequired,
                  errors: _vm.firstNameErrors
                },
                model: {
                  value: _vm.firstName,
                  callback: function($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Last Name - PRE05",
                  "model-name": "universal_claim_form",
                  name: "prescriber_last_name",
                  required: _vm.lastNameRequired,
                  errors: _vm.lastNameErrors
                },
                model: {
                  value: _vm.lastName,
                  callback: function($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "ml-1 mr-1" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("text-input", {
                attrs: {
                  label: "Phone Number - PRE08",
                  "model-name": "universal_claim_form",
                  name: "prescriber_phone_number",
                  required: _vm.phoneNumberRequired,
                  errors: _vm.phoneNumberErrors
                },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }