var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "sftp-conversion-modal",
          attrs: {
            "hide-footer": "",
            id: "sftp-conversion-modal",
            "no-close-on-backdrop": true,
            "hide-header-close": true,
            "no-close-on-esc": true
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function() {
                return [
                  _c(
                    "h3",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle-fill",
                          scale: "1",
                          variant: "danger"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("SFTP Transition")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm.step == 1
            ? _c(
                "div",
                { staticClass: "step-1" },
                [
                  _vm._v(
                    "\n      The PMP Clearinghouse will be transitioning to a new SFTP server.\n      This transition will require you to update your current SFTP account credentials and hostname for your substance reports to be submitted.\n      "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-3 float-right",
                      attrs: { variant: "primary" },
                      on: { click: _vm.showForm }
                    },
                    [_vm._v("Continue")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? _c(
                "div",
                { staticClass: "step-2" },
                [
                  _c("h5", [_vm._v("Create new SFTP account")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n        Creating a new account will not affect your current SFTP submissions. The account details below will be used once you transition to the new SFTP server.\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("strong", [_vm._v("Account details")]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "mt-0" }),
                  _vm._v(" "),
                  _c(
                    "b-form",
                    {
                      attrs: {
                        action: "/sftp_logins/transition",
                        novalidate: true,
                        method: "post",
                        "accept-charset": "UTF-8"
                      }
                    },
                    [
                      _c("input", {
                        attrs: { name: "utf8", type: "hidden", value: "✓" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "bg-light" }, [
                        _c("div", { staticClass: "pt-2 pb-2" }, [
                          _c(
                            "small",
                            [
                              _c(
                                "b-row",
                                { staticClass: "ml-1 mr-1" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("text-input", {
                                        attrs: {
                                          label: "Hostname",
                                          value: _vm.hostname,
                                          disabled: true,
                                          errors: _vm.sftp_login.hostname.errors
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { staticClass: "ml-1 mr-1" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("text-input", {
                                        attrs: {
                                          label: "SFTP Username",
                                          errors: _vm.sftp_login.name.errors,
                                          name: "name",
                                          "model-name": "sftp_login"
                                        },
                                        model: {
                                          value: _vm.sftp_login.name.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.sftp_login.name,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "sftp_login.name.value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                { staticClass: "ml-1 mr-1" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("text-input", {
                                        attrs: {
                                          label: "SFTP Password",
                                          type: "password",
                                          autocomplete: false,
                                          hint: _vm.passwordHint,
                                          errors:
                                            _vm.sftp_login.password.errors,
                                          name: "password",
                                          "model-name": "sftp_login"
                                        },
                                        model: {
                                          value: _vm.sftp_login.password.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.sftp_login.password,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sftp_login.password.value"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("text-input", {
                                        attrs: {
                                          label: "SFTP Password Confirmation",
                                          type: "password",
                                          autocomplete: false,
                                          errors:
                                            _vm.sftp_login.password_confirmation
                                              .errors,
                                          name: "password_confirmation",
                                          "model-name": "sftp_login"
                                        },
                                        model: {
                                          value:
                                            _vm.sftp_login.password_confirmation
                                              .value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.sftp_login
                                                .password_confirmation,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "sftp_login.password_confirmation.value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "mt-0" }),
                      _vm._v(" "),
                      _c("small", [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*")
                        ]),
                        _vm._v(
                          "Write down the hostname, username and password for later use."
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "submit float-right",
                          class: _vm.buttonClass,
                          attrs: {
                            variant: "primary",
                            disabled: _vm.submitting
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("Continue")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 3
            ? _c("div", { staticClass: "step-3" }, [
                _c("h4", { staticClass: "text-center" }, [
                  _vm._v("\n        Next steps\n      ")
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _vm._v(
                      "\n        You have successfully created credentials for the new SFTP server. To complete the SFTP transition process and begin using the server now, click "
                    ),
                    _c(
                      "b-link",
                      { attrs: { href: _vm.sftp_login.success_path } },
                      [_vm._v("here")]
                    ),
                    _vm._v(".\n        "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(
                      "\n        To complete the SFTP transition process later, click "
                    ),
                    _c(
                      "b-link",
                      { attrs: { href: "#" }, on: { click: _vm.closeModal } },
                      [_vm._v("here")]
                    ),
                    _vm._v(".\n        "),
                    _c("br")
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }