<template>
  <b-form-group
    :state="state"
    :invalid-feedback="errorMessage"
  >
    <b-form-label :required="required" :forId="inputId" :class="{'font-weight-bold': required}">
      {{ inputLabel }}
    </b-form-label>
    <b-input-group>
      <b-form-input
        v-model="identifierValue"
        :state="state"
        :id="inputId"
        :name="inputName"
        maxlength="255"
        size="255"
        :class="{'font-weight-bold': required}"
      >
      </b-form-input>
      <b-input-group-append v-if="withSearchAndAutoFill">
        <button
          class="btn tipsy"
          :id="buttonId"
          type="button"
          data-toggle="tipsy"
          @click="search"
          title="Search and Autofill"
        >
          <i class="fas fa-search"></i>
        </button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
  import axios from "axios"
  import BFormLabel from './BFormLabel.vue'

  export default {
    components: {
      "b-form-label": BFormLabel
    },
    data() {
      return {
        identifierValue: this.value,
        errorMessages: this.errors,
        stateCd: this.state_cd,
        withSearchAndAutoFill: this.withSearchAndAutoFill,
      }
    },
    computed: {
      state() {
        return this.invalid ? false : null
      },
      invalid() {
        return !!this.errorMessages.length;
      },
      inputName() {
        return `${this.modelName}[${this.attrName}]`;
      },
      inputId() {
        return `${this.modelName}_${this.attrName}`;
      },
      buttonId() {
        return `search_${this.inputId}`;
      },
      errorMessage() {
        return this.errorMessages.join(' and ');
      },
      lookupPath() {
        return `/api/v1/identifiers/${this.identifierValue}`
      },
      inputLabel() {
        return this.label || `${this.qualifier} Number`
      }
    },
    props: {
      qualifier: {
        type: String,
        default: null
      },
      value: {
        type: String,
        required: false
      },
      state_cd: {
        type: String,
        required: false
      },
      required: {
        type: Boolean,
        required: true
      },
      modelName: {
        type: String,
        required: true
      },
      attrName: {
        type: String,
        required: true
      },
      errors: {
        type: Array,
        default: () => []
      },
      label: {
        type: String,
        required: false
      },
      notFoundError: {
        type: String,
        default: "We could not find a matching record."
      },
      noQualifierProvidedError: {
        type: String,
        required: false
      },
      withSearchAndAutoFill: {
        type: String,
        required: false,
        default: true,
      },
    },
    methods: {
      makeRequest() {
        let vm = this;

        axios.get(vm.lookupPath, {
          params: {
            qualifier: this.qualifier,
            state_cd: this.stateCd
          }
        }).then(function (response) {
            vm.$emit('found', vm.qualifier, response.data);
            vm.errorMessages = [];
          })
          .catch(function (error) {
            vm.errorMessages = [vm.notFoundError];
          });
      },
      search() {
        if (this.qualifier) {
          this.makeRequest();
        } else if (this.noQualifierProvidedError) {
          this.errorMessages = [this.noQualifierProvidedError];
        }
      }
    }
  }
</script>
