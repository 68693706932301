<template>
  <b-form-group
    :state="state"
    :invalid-feedback="errorMessage"
  >
    <b-form-label :required="required" :forId="inputId" :class="{'font-weight-bold': required}">
      {{ title }}
    </b-form-label>
    <b-form-input
      v-model="inputValue"
      :state="state"
      :id="inputId"
      :name="inputName"
      maxlength="255"
      size="255"
      :type="type"
      :class="{'font-weight-bold': required}"
      :autocomplete="autocompleteAttr"
      :disabled="disabled"
    >
    </b-form-input>
    <small v-if="hint" class="form-text text-muted">
      {{ hint }}
    </small>
  </b-form-group>
</template>

<script>
  import BFormLabel from './BFormLabel.vue'

  export default {
    components: {
      "b-form-label": BFormLabel
    },
    computed: {
      autocompleteAttr() {
        return this.autocomplete ? "on" : "off";
      },
      state() {
        return this.invalid ? false : null;
      },
      invalid() {
        return !!this.errors.length;
      },
      inputValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
      inputName() {
        return this.disabled ? '' : `${this.modelName}[${this.name}]`;
      },
      inputId() {
        return `${this.modelName}_${this.name}`;
      },
      errorMessage() {
        return this.errors.join(' and ');
      },
      title() {
        return this.label || this.nameHumanized;
      },
      nameHumanized() {
        return this.name.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
      }
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        required: false
      },
      name: {
        type: String,
        required: false
      },
      errors: {
        type: Array,
        default: () => []
      },
      label: {
        type: String,
        required: false
      },
      modelName: {
        type: String,
        required: false
      },
      type: {
        type: String,
        default: "text"
      },
      autocomplete: {
        type: Boolean,
        default: false
      },
      hint: {
        type: String,
        required: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
