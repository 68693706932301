<template>
  <b-form-group
    :state="state"
    :invalid-feedback="errorMessage"
  >
    <b-form-label :required="required">
      {{ label }}
    </b-form-label>
    <b-form-checkbox-group
      :checked="value"
      :required="required"
      :options="options"
      text-field="name"
      value-field="id"
      :name="inputName"
      :id="inputId"
      stacked
      :state="state"
    >
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
  import BFormLabel from './BFormLabel.vue'

  export default {
    components: {
      "b-form-label": BFormLabel
    },
    computed: {
      state() {
        return this.invalid ? false : null;
      },
      invalid() {
        return !!this.errors.length;
      },
      inputName() {
        return `${this.modelName}[${this.name}][]`;
      },
      inputId() {
        return `${this.modelName}_${this.name}`;
      },
      errorMessage() {
        return this.errors.join(' and ');
      }
    },
    props: {
      value: {
        type: Array,
        default: () => []
      },
      options: {
        type: Array,
        required: true
      },
      required: {
        type: Boolean,
        required: false
      },
      name: {
        type: String,
        required: true
      },
      errors: {
        type: Array,
        default: () => []
      },
      label: {
        type: String,
        required: false
      },
      modelName: {
        type: String,
        required: true
      }
    }
  }
</script>
