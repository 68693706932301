<template>
  <table class="table table-bordered-around">
    <thead>
      <tr>
        <th></th>
        <th>Pharmacy</th>
        <th>License Number</th>
        <th>NCPDP</th>
        <th>DEA Number</th>
        <th>NPI</th>
        <th>Actions</th>
        <th>Submit Zero Reports for:</th>
      </tr>
    </thead>
    <tbody>
      <template v-for='pmp in pmps'>
        <tr @click="loadProfiles(pmp)" class="pmp-collapse">
          <td>
            <span v-if="profiles[pmp.id].loading">
              <b-spinner style="width: 1.3rem; height: 1.3rem;"></b-spinner>
            </span>
            <i v-else class="fa fa-lg" :class="pmpCollapseIcon(pmp)"></i>
            <b>{{ pmp.name }}</b>
          </td>
          <td colspan="7"></td>
        </tr>
        <tr v-for="(profile, index) in profiles[pmp.id].list" :key="profile.id" v-show="profiles[pmp.id].open" class="profile-collapse">
          <td></td>
          <td class="align-middle">{{ profile.pharmacy }}</td>
          <td class="align-middle">{{ profile.permit_license_number }}</td>
          <td class="align-middle">{{ profile.ncpdp }}</td>
          <td class="align-middle">{{ profile.dea }}</td>
          <td class="align-middle">{{ profile.npi }}</td>
          <td class="align-middle">
            <div class='action-links'>
              <a :href="editProfilePath(profile)">Edit</a>
              <a href="#" @click.stop.prevent="deleteProfile(pmp, profile, index)">Delete</a>
            </div>
          </td>
          <td class="submission">
            <submit-button v-for="(submitted, day) in profile.submissions"
              :profile-id="profile.id"
              :day="day"
              :date="dates[day]"
              :submitted="submitted"
            >
            </submit-button>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
  import axios from "axios"
  import ZeroReportSubmitButton from './ZeroReportSubmitButton.vue';

  export default {
    data() {
      return {
        profiles: this.pmps.reduce(function(acc, cur) {
          return { ...acc,
            [cur.id]: {
              open: false,
              loading: false,
              list: []
            }
          }
        }, {})
      }
    },
    components: {
      'submit-button': ZeroReportSubmitButton
    },
    computed: {
      dates() {
        return {
          today: this.formatted(this.today),
          yesterday: this.formatted(this.yesterday)
        }
      },
      today() {
        return new Date();
      },
      yesterday() {
        let date = new Date();
        date.setDate(this.today.getDate() - 1);
        return date;
      }
    },
    methods: {
      formatted(date) {
        return date.toLocaleDateString('en-US', { timeZone: this.timeZone });
      },
      profilesPath(pmp) {
        return `pmps/${pmp.id}/zero_report_profiles.json`;
      },
      profilePath(profile) {
        return `zero_report_profiles/${profile.id}`;
      },
      editProfilePath(profile) {
        return `${this.profilePath(profile)}/edit`;
      },
      deleteProfile(pmp, profile, index) {
        var profiles = this.profiles[pmp.id];

        if(confirm('Are you sure?')) {
          axios.delete(this.profilePath(profile))
            .then(resp => {
              profiles.list.splice(index, 1);
            })
        }
      },
      pmpCollapseIcon(pmp) {
        return this.profiles[pmp.id].open ? "fa-minus-circle" : "fa-plus-circle";
      },
      toggle(pmp) {
        this.profiles[pmp.id].open = !this.profiles[pmp.id].open;
      },
      loadProfiles(pmp) {
        var profiles = this.profiles[pmp.id];

        if (!profiles.list.length) {
          profiles.loading = true;
          axios
            .get(this.profilesPath(pmp))
            .then(function(response) {
              profiles.list = response.data;
              profiles.loading = false;
              profiles.open = true;
            })
        } else {
          this.toggle(pmp);
        }
      }
    },
    props: {
      pmps: {
        type: Array,
        required: true
      },
      timeZone: {
        type: String,
        required: true
      }
    }
  }
</script>

<style scoped>
  tr.pmp-collapse {
    cursor: pointer;
  }

  tr.profile-collapse td {
    background-color: #f2f2f2;
  }

  .action-links > a {
    text-align: center;
    padding: 3px 15px;
    border-left: 1px solid #d7d7d7;
  }

  .action-links a:first-child {
    border-left: none;
    padding-left: 0px;
  }
</style>
