var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.success
    ? _c("span", { staticClass: "notice success" }, [
        _c("i", { staticClass: "fa fa-check" }),
        _vm._v("\n  Submitted\n")
      ])
    : _vm.error
    ? _c("span", { staticClass: "notice error" }, [
        _c("i", { staticClass: "fa fa-times" }),
        _vm._v("\n  Error\n")
      ])
    : _c(
        "b-button",
        {
          staticClass: "submit",
          class: _vm.buttonClass,
          attrs: { variant: "primary", disabled: _vm.submitting },
          on: { click: _vm.submit }
        },
        [
          _vm.submitting
            ? _c(
                "span",
                [
                  _c("b-spinner", { attrs: { small: "" } }),
                  _vm._v("\n    Submitting\n  ")
                ],
                1
              )
            : _c("span", [
                _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
                _c("br"),
                _vm._v("\n    " + _vm._s(_vm.date) + "\n  ")
              ])
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }